function _GetDateTimeStr(now, fmt) {
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  const days_ko = ['일','월','화','수','목','금','토'];

  let fullyear = ""+now.getFullYear();
  let year = fullyear.slice(2);
  let month = "0"+(now.getMonth() + 1);
  if (month.length > 2) month = month.slice(1);
  let day = "0"+now.getDate();
  if (day.length > 2) day = day.slice(1);
  let hour = "0"+now.getHours();
  if (hour.length > 2) hour = hour.slice(1);
  let minute = "0"+now.getMinutes();
  if (minute.length > 2) minute = minute.slice(1);
  let sec = "0"+now.getSeconds();
  if (sec.length > 2) sec = sec.slice(1);

  let time_str = fmt;
  time_str = time_str.replace('$yyyy', fullyear);
  time_str = time_str.replace('$yy', year);
  time_str = time_str.replace('$mm', month);
  time_str = time_str.replace('$month', months[now.getMonth()])
  time_str = time_str.replace('$dayko', days_ko[now.getDay()])
  time_str = time_str.replace('$day', days[now.getDay()])
  time_str = time_str.replace('$dd', day);
  time_str = time_str.replace('$HH', hour);
  time_str = time_str.replace('$MM', minute);
  time_str = time_str.replace('$ss', sec);
  return time_str;
}

export default {
  CSVToArray( strData, strDelimiter ){
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    let objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
        );


    // Create an array to hold our data. Give the array
    // a default empty first row.
    let arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;


    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec( strData ))){

      // Get the delimiter that was found.
      let strMatchedDelimiter = arrMatches[ 1 ];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
          ){

          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push( [] );

      }

      let strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[ 2 ]){

          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          strMatchedValue = arrMatches[ 2 ].replace(
              new RegExp( "\"\"", "g" ),
              "\""
              );

      } else {

          // We found a non-quoted value.
          strMatchedValue = arrMatches[ 3 ];

      }


      // Now that we have our value string, let's add
      // it to the data array.
      arrData[ arrData.length - 1 ].push( strMatchedValue );
    }

    // Return the parsed data.
    return( arrData );
  },
  DownloadCSV(arr, fmt) {
    const exportData = arr;
    const uBom = "\ufeff";
    let csvContent = "data:text/csv;charset=utf-8," + uBom;
    const now = new Date();
    let filename = _GetDateTimeStr(now, fmt);

    csvContent += [
        Object.keys(exportData[0]).join(","),
        ...exportData.map(item => {
        let vals = Object.values(item)
        for (let i in vals) {
            if (typeof vals[i] == 'string') {
            vals[i] = vals[i].replace(/"/g, '""')
            }
        }
        return `"${vals.join('","')}"`
        })
    ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

    const data = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  },
  GetDateTimeStr(fmt, time) {
    let t = time || new Date()
    return _GetDateTimeStr(t, fmt);
  },
  Range(start, stop, step) {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    let result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
  },
  RandomString(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  RandomInteger(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
  },
  GetHourStr(num) {
    if (num < 10) {
      return `0${num}:00`;
    }
    return `${num}:00`;
  },
  DataURI2Blob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: mimeString});
  },
  EraseCanvas(canvas) {
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "#ddd";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  },
  ContainImageSize(img, max_w, max_h) {
    let canvas = document.createElement('canvas');

      // 1. Resize 할 필요가 없다면 원본 이미지 사용.
      canvas.width = img.width
      canvas.height = img.height

      // 2. Height가 너무 클 때
      if (max_h < img.height) {
        canvas.height  = max_h;
        canvas.width = img.width * max_h / img.height;
      }

      // 3. Width가 너무 클 때
      if (max_w < canvas.width) {
        canvas.width = max_w
        canvas.height = img.height * max_w / img.width;
      }

      let ctx = canvas.getContext('2d');
      ctx.save();
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.restore();
      return canvas.toDataURL("image/jpeg", 1.0);
  },
  SubscriptionRemainHour(service) {
    const HOUR_MILI = 1000 * 60 * 60;
    const DAY_MILI = HOUR_MILI * 24;
    const start_from = new Date(service.created_at).getTime();
    const now = Date.now();
    const diff_mili = now - start_from;
    const period_mili = DAY_MILI * service.period_day;
    const remain_mili = period_mili - diff_mili;
    return parseInt(remain_mili / HOUR_MILI);
  },
  getSubscriptionStatusColor: function(service) {
    const remain_hours = this.SubscriptionRemainHour(service);
    if (remain_hours > 30 * 24) {
      return 'success';
    } else if (remain_hours > 7 * 24) {
      return 'primary';
    } else if (remain_hours > 3 * 24) {
      return 'warning';
    } else if (remain_hours > 0) {
      return 'danger';
    }
    return 'light';
  },
  getSubscriptionStatusRemain: function(service) {
    const remain_hours = this.SubscriptionRemainHour(service);
    const remain_days = parseInt(remain_hours / 24) + 1;
    if (remain_hours > 0) {
      return `${remain_days} days remain`;
    }
    return 'Expired';
  },
  copy(src) {
    return JSON.parse(JSON.stringify(src));
  }
}
