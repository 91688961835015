import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n'
import locale_en from 'element-ui/lib/locale/lang/en'
import locale_ko from 'element-ui/lib/locale/lang/ko'
import messages from './assets/i18n-messages.json'
import 'element-ui/lib/theme-chalk/index.css'
import { iconsSet as icons } from './assets/icons/icons.js'
import * as svgicon from 'vue-svgicon'
import store from './store'
import api from './api'
import AxiosConfig from './config'
import resource from './static_resources.json'
// import ws from './ws'
import axios from 'axios'
import './icons';
import utililty from './utility.js';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
AxiosConfig()

Vue.prototype.$resource = resource;
Vue.prototype.$api = api;
Vue.prototype.$utility = utililty;
// Vue.prototype.$ws = ws;
// ws.TryConnectWebsocket();
// ws.Maintenance();

Vue.use(ElementUI, { locale : locale_en });
Vue.use(svgicon, {
  tagName: 'svgicon',
  defaultWidth: '50px',
  defaultHeight: '50px'
});
Vue.use(VueLayers);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-tooltip', LTooltip);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// axios.get(`/api/capability/`)
//   .then(response => {
//     store.commit('set', ['capability', response.data]);
//     lang = store.state.capability.user_profile.locale;
//   })
//   .catch(error => {
//     console.error(error);
//     if (error.response.status === 403) {
//       router.push('/pages/login');
//     }
//   })
//   .finally(() => {
//     const i18n = new VueI18n({
//       locale: lang,
//       fallbackLocale: 'en',
//       messages: messages,
//     })
//     Vue.use(ElementUI, { locale : locales[lang] });
//     new Vue({
//       el: '#app',
//       router,
//       store,
//       icons,
//       i18n,
//       template: '<App/>',
//       components: {
//         App
//       }
//     })
//   })

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/service/delivery/wearing/')) {
    return next();
  }
  if (to.path === '/pages/login') {
    // if (store.state.capability) {
    //   return next('/')
    // }
    return next();
  }
  if (store.state.capability) {
    return next();
  }
  axios.get(`/api/capability/`)
    .then(response => {
      store.commit('set', ['capability', response.data]);
      localStorage.lang = response.data.user_profile.locale;
      next();
    })
    .catch(error => {
      console.error(error);
      console.log(to);
      next(`/pages/login?redirect=${window.btoa(to.fullPath)}`);
    })
});

Vue.use(VueI18n);
const locales = {
  en: locale_en,
  ko: locale_ko
}
let lang = localStorage.lang || 'en';
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: messages,
})
Vue.use(ElementUI, { locale : locales[lang] });
new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
