import axios from 'axios'
import Cookie from 'js-cookie'

// documents
// https://github.com/mzabriskie/axios

export default function () {
  // axios.defaults.baseURL = 'http://192.168.200.210';
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.withCredentials = true

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      let csrfToken = Cookie.get('csrftoken')

      if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken
        config.withCredentials = true
      }

      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      if (response.config.parse) {
        //perform the manipulation here and change the response object
      }
      return response;
    },
    (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // window.location.reload();
        console.log(window.location);
        // let url = `${window.location.origin}/#/pages/login`;
        // if (!window.location.hash.startsWith('#/pages/login')) {
        //   url = url + '?redirect=' + window.location.hash.replace('#', '');
        //   window.location.href = url;
        // }
        return Promise.reject(error.message);
      }
      return Promise.reject(error);
    }
  )
}
